import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/basic/subprojectsCost/page',
    method: 'post',
    data: query
  })
}

/**
 * 通过ids批量删除
 */
export function deltrids (ids) {
  return request({
    url: '/basic/customerproject/deleteCustomerProject',
    method: 'post',
    params: ids
  })
}

/**
* 通过id查询*
*/
export function tredit (id) {
  return request({
    url: '/basic/subprojectsCost/querySubprojectsCostMessage/' + id,
    method: 'get'
  })
}
/**
 * 新增 铁路线路成本保存
 */
export function saveLineCb (obj) {
  return request({
    url: '/basic/costRailwayLine/insertRailwayLine',
    method: 'post',
    data: obj
  })
}
/**
* 更新 铁路线路成本保存
*/
export function updateLineCb (obj) {
  return request({
    url: '/basic/costRailwayLine/updateRailwayLine',
    method: 'post',
    data: obj
  })
}
/**
 * 通过id删除 铁路线路成本
 */
export function delLineCb (id) {
  return request({
    url: '/basic/costRailwayLine/deleteRailwayLine',
    method: 'post',
    params: { id: id }
  })
}
/**
 * 通过主表id查询铁路线路成本 线路名称
 */
export function selectRailwayLine (id) {
  return request({
    url: '/basic/costRailwayLine/selectRailwayLine',
    method: 'post',
    params: { id: id }
  })
}
/**
 * 暂存保存
 */
export function temporarySave (obj) {
  return request({
    url: '/basic/subprojectsCost/stagingSubprojectsCost',
    method: 'post',
    data: obj
  })
}
/**
 * 新增保存
 */
export function addSubProject (obj) {
  return request({
    url: '/basic/subprojectsCost/insertSubprojectsCost',
    method: 'post',
    data: obj
  })
}
/**
* 更新保存
*/
export function updateSubProject (obj) {
  return request({
    url: '/basic/subprojectsCost/updateSubprojectsCost',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行 初审
 */
export function auditSubProject (ids) {
  return request({
    url: '/basic/subprojectsCost/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反初审
 */
export function unAuditSubProject (ids) {
  return request({
    url: '/basic/subprojectsCost/unAudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 复审
 */
export function examineSubProject (ids) {
  return request({
    url: '/basic/subprojectsCost/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反复审
 */
export function unExamineSubProject (ids) {
  return request({
    url: '/basic/subprojectsCost/unExamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过id进行 启用
 */
export function enableObj (ids) {
  return request({
    url: '/basic/subprojectsCost/enableStatus',
    method: 'post',
    params: ids
  })
}
/**
 * 通过id进行 停用
 */
export function stopObj (ids) {
  return request({
    url: '/basic/subprojectsCost/disableStatus',
    method: 'post',
    params: ids
  })
}
/**
 * 根据始发地目的地查询公里数
 */
export function obtainKilometers (obj) {
  return request({
    url: '/basic/subprojectsCost/obtainKilometerInformation',
    method: 'post',
    params: obj
  })
}
/**
 * 更新中标状态
 */
export function updateBidStatusById (obj) {
  return request({
    url: '/basic/subprojectsCost/updateBidStatusById',
    method: 'post',
    params: obj
  })
}
/**
 * 根据id同步到子项目报价单主表以及明细表信息
 */
export function synchronizeSubProject (id) {
  return request({
    url: '/basic/subprojectsQuotation/synchronizeSubProject',
    method: 'post',
    params: { id: id }
  })
}
/**
 * 判断项目是否同行
 */
export function judgeProjectPeers (customerId) {
  return request({
    url: '/basic/dtcustomer/judgeProjectPeers',
    method: 'post',
    params: { customerId: customerId }
  })
}
/**
 * 判断报价单模板是否变动
 */
export function checkPricePrintInfo (obj) {
  return request({
    url: '/basic/subprojectsCost/checkPricePrintInfo',
    method: 'post',
    data: obj
  })
}
/**
 * 前端打印接口-获取打印数据
 */
export function getPrintPriceInfo (id) {
  return request({
    url: '/basic/subprojectsCost/getPrintPriceInfo/' + id,
    method: 'get'
  })
}
/**
 * 保存打印
 */
export function insertPrintRecords (obj) {
  return request({
    url: '/basic/printRecords/insertPrintRecords',
    method: 'post',
    data: obj
  })
}
/**
 * 打印报价单
 */
export function reportPrintingQuotation (obj) {
  return request({
    url: '/basic/subprojectsCost/reportPrintingQuotation',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 打印确认函 id(子项目成本单主键)
 */
export function reportSavePrintingConfirm (id) {
  return request({
    url: '/basic/subprojectsCost/reportSavePrintingConfirm',
    method: 'get',
    responseType: 'blob',
    params: { id: id }
  })
}
/**
 * 打印记录 打印确认函 id(子项目成本单主键)
 */
export function reportPrintingConfirm (id) {
  return request({
    // url: '/basic/subprojectsCost/reportPrintingConfirm',
    url: '/basic/subprojectsCost/printQuotationByRecordId/' + id,
    method: 'post',
    responseType: 'blob'
  })
}
/**
 * 打印记录表 查询列表
 */
export function fetchPrintList (query) {
  return request({
    url: '/basic/printRecords/getPrintRecordsPage',
    method: 'post',
    data: query
  })
}
/**
 * 根据id查询打印记录主表以及明细表数据*
 */
export function getPrintRecord (id) {
  return request({
    url: '/basic/printRecords/queryPrintRecordsMessage/' + id,
    method: 'get'
  })
}
/**
 * 通过id删除打印记录主表以及明细表数据
 */
export function deletePrintRecords (id) {
  return request({
    url: '/basic/printRecords/deletePrintRecords',
    method: 'post',
    params: { id: id }
  })
}
/**
 * 获取子项目（搜索下拉）
 */
export function getSubprojectCost (subproject) {
  return request({
    url: '/basic/subprojectsCost/getDropDownListData',
    method: 'get',
    params: { subproject: subproject }
  })
}
/**
 * 列表预览子项目明细
 */
export function getDetails (id) {
  return request({
    url: '/basic/subprojectsCost/getSubprojectCostsList/' + id,
    method: 'get'
  })
}
